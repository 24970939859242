import * as React from 'react';
import {graphql} from 'gatsby';
import { Helmet } from 'react-helmet';
import Link from '../components/Link/Link';
import { useLocation } from '@reach/router';

import { BuilderComponent } from '@builder.io/react';
import C3LogoFavicon from "../assets/icon/favicon.ico";

const defaultDescription = '';

const defaultTitle = 'Builder: Drag and Drop Page Building for Any Site';

function LandingPageTemplate({data}) {
  const models = data?.allBuilderModels;
  const landingPage = models.landingPage[0]?.content;

  // HandleSpecificRedirections();

  return (
    <>
      <Helmet>
        <title>{(landingPage && landingPage.data.title) || defaultTitle}</title>
        <link rel="icon" type="image/x-icon" href={C3LogoFavicon}></link>
        <meta
          name="description"
          content={
            (landingPage && landingPage.data.description) || defaultDescription
          }
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=0;" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content={useLocation().href} />
        <meta property="og:title" content={landingPage?.data.title} />
        <meta property="og:description" content={landingPage?.data.description} />
        <meta property="og:image" content={landingPage?.data.imagePreview} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="C3 Church Global" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={landingPage?.data.title} />
        <meta name="twitter:image" content={landingPage?.data.image} />

        <script src="https://www.google.com/recaptcha/api.js?render=6LeSMjQnAAAAAIHjJto5pVI1fsZ9DRKruUSsywDz"></script>

      </Helmet>
      <BuilderComponent
          renderLink={Link}
          name="landing-page"
          content={landingPage}
        />
    </>
  );
}

// const HandleSpecificRedirections = () => {
//   const currentPath = useLocation().pathname;
//
//   const reachNetworkPathRedirections = ['/region-c3-mena']; // Old url
//   const reachNetworkPathname = '/region-c3-reach-network';
//
//   const eastAndWestAfricaPathRedirections = ['/region-c3-east-africa', '/region-c3-west-africa']; // Old urls
//   const eastAndWestPathname = '/region-c3-east-and-west-africa';
//
//   if (reachNetworkPathRedirections.includes(currentPath)) {
//     navigate(reachNetworkPathname);
//   } else if (eastAndWestAfricaPathRedirections.includes(currentPath)) {
//     navigate(eastAndWestPathname);
//   }
// }

export default LandingPageTemplate;

export const landingPageQuery = graphql`
  query($path: String!) {
    allBuilderModels {
      landingPage(
        target: { urlPath: $path }
        limit: 1
        options: { cachebust: true }
      ) {
        content
      }
    }
  }
`;
